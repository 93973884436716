<template>
  <dialogComp :title="dialogType==='add'?'新增商家':'编辑商家'" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item prop="storeName" label="客户全称:">
        <merchant
          :hval="formData.storeName"
          @storeNameChange="storeNameChange"
          :disabled="dialogType==='edit'"
        />
      </el-form-item>
      <inputFormComp
        prop="storeAbbreviation"
        label="客户简称:"
        :hval="formData.storeAbbreviation"
        @blur="storeAbbreviationkBlur"
         placeholder="请输入客户简称"
      />
      <el-form-item label="省市区:" prop="area">
        <areaData :hval="formData.area" @change="areaChange" />
      </el-form-item>
      <inputFormComp
        label="公司介绍:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入公司介绍"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import areaData from '@/views/components/areaDataNew'
import merchant from '@/views/components/merchant'
import { supplierManageAdd, supplierManageEdit } from '@/api'
import { handleArea } from '@/utils/common'
export default {
  components: {
    merchant,
    areaData
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        storeName: '',
        storeId: '',
        storeAbbreviation: '',
        province: '',
        city: '',
        region: '',
        area: [],
        remark: ''
      },
      rules: {
        storeName: [{ required: true, message: '请选择客户全称', trigger: 'change' }],
        storeAbbreviation: [{ required: true, message: '请输入客户简称', trigger: 'blur' }],
        area: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val && this.dialogType === 'edit') {
        const { storeName, storeId, storeAbbreviation, remark, provinceCityRegion, province, city, region } = this.info
        setTimeout(() => {
          this.formData.storeName = storeName
          this.formData.storeId = storeId
        }, 300)
        this.formData.storeAbbreviation = storeAbbreviation
        this.formData.remark = remark
        if (!provinceCityRegion) return
        this.formData.area = handleArea(province, city, region)
        const [a, b, c] = this.formData.area
        this.formData.province = a || ''
        this.formData.city = b || ''
        this.formData.region = c || ''
      }
    }
  },
  methods: {
    storeNameChange (val) {
      this.formData.storeId = val.partnerId
      this.formData.storeName = val.partnerName
    },
    storeAbbreviationkBlur (val) {
      this.formData.storeAbbreviation = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    close () {
      this.formData = {
        storeName: '',
        storeId: '',
        storeAbbreviation: '',
        province: '',
        city: '',
        region: '',
        area: [],
        remark: ''
      }
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.dialogType === 'add') {
            supplierManageAdd(this.formData).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            supplierManageEdit({ ...this.formData, id: this.info.id }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
