<template>
  <div class="layout_common roles_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              :hval="name"
              @blur="nameBlur"
              placeholder="商家名"
            />
            <dateComp :hval="date" startPlaceholder="报障开始日期" endPlaceholder="报障结束日期" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增'" >新增</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <el-table-column slot="storeName" label="商家全称">
            <template v-slot="scope">
              <router-link
                target="_blank"
                :to="{ name: 'supplier-detail', query: {id:scope.row.id,storeName:scope.row.storeName, storeId: scope.row.storeId} }"
              >
                {{ scope.row.storeName }}
              </router-link>
            </template>
          </el-table-column>
        <el-table-column slot="operate" label="操作" fixed="right" width="130">
            <template v-slot="scope">
               <btnComp btnType="text" @click="add('edit',scope.row)" v-permission="'编辑'">编辑</btnComp>
              <!-- <btnComp style="color:red" btnType="text" @click="del(scope.row)">删除</btnComp> -->
            </template>
        </el-table-column>
        </tableComp>
      </div>
    </div>
    <add :isShow="addShow" @close="close" :dialogType="dialogType" :info="info" />
    <dialogComp
        title="提示"
        :isShow="hintShow"
        @close="close"
        @sure="hintSure"
      >
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import add from './add.vue'
import { supplierManageList, supplierManageDel } from '@/api'
export default {
  components: {
    add
  },
  data () {
    return {
      addShow: false,
      hintShow: false,
      dataAuthority: false,
      dialogType: '',
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      startTime: '',
      endTime: '',
      date: [],
      tableData: [],
      theadName: [
        { prop: 'remark', label: '备注' },
        // { prop: 'storeName', label: '商家全称', width: 240 },
        { slot: 'storeName' },
        { prop: 'storeAbbreviation', label: '商家简称' },
        { prop: 'provinceCityRegion', label: '区域' },
        { prop: 'memberNum', label: '人员' },
        { prop: 'addressNum', label: '地址' },
        { prop: 'categoryNum', label: '商家自管设备类目' },
        { prop: 'behalfNum', label: '平台托管工单数量' },
        { prop: 'selfNum', label: '商家自管工单数量' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        name: this.name,
        startTime: this.startTime,
        endTime: this.endTime
      }
      supplierManageList(params).then((res) => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    nameBlur (val) {
      this.name = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    add (type, info) {
      this.addShow = true
      this.dialogType = type
      if (type === 'edit') {
        this.info = info
      }
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      supplierManageDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
